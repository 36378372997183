<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="transparencyTypes"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height"
      fixed-header
      disable-sort
    >

      <!-- name -->
      <template #[`item.name`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <router-link :to="`/transparency/type/${item.id}`" class="btn-releases center-router">
            <v-icon size="22">{{ icons.mdiEyeSettings }}</v-icon>
          </router-link>
          <CrudButtons @edit="$emit('edit', item)" @remove="$emit('remove', item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiEyeSettings  } from "@mdi/js";
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    CrudButtons,
  },
  props: {
    transparencyTypes: Array,
    search: String,
    loading: Boolean,
  },
  setup() {
    const statusColor = {
      Activo: 'primary',
      Inactivo: 'error',
    }

    const loadImage = src => {
      return loadAPIImage(src)
    }

    return {
      loadImage,
      headers: [
        { text: 'NOMBRE', value: 'name' },
        { text: 'FECHA REGISTRO', value: 'created_at' },
        { text: 'ULTIMA ACTUALIZACION', value: 'updated_at' },
        { text: '', value: 'actions', align: 'right' },
      ],
      status: {
        1: 'Activo',
        2: 'Inactivo',
        //3: 'Rechazado',
      },
      icons: {
        mdiEyeSettings,
      },
      statusColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.img {
  width: 150px;
  height: 150px;
  padding: 10px;
  margin: 10px;
}

.img img {
  max-width: 100px;
  max-height: 100px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-router{
  margin: auto 0;
  padding-right: 8px
}
</style>

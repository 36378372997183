<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formTransparencyTypes" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="12">
            <label for="Subtype">Nombre</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-text-field
              id="Subtype"
              v-model="transparencyTypes.name"
              outlined
              dense
              placeholder="Nombre"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'

export default {
  components: {
    FormTemplate,
  },
  props: {
    transparencyTypesEdited: Object,
    loading: Boolean,
    idTypes: String,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      transparencyTypes: this.transparencyTypesEdited ? { ...this.transparencyTypesEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formTransparencyTypes.validate()
    },
    reset() {
      this.$refs.formTransparencyTypes.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.transparencyTypesEdited) {
        let DTO = { ...this.transparencyTypes}
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.transparencyTypes }
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useTransparencyTypes = () => {

  // base data
  const dialog = ref(false);
  const transparencyTypesEdited = ref(null);
  const transparencyTypes = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    transparencyTypesEdited.value ? "Editar Transparencia" : "Agregar Transparencia"
  );
  const subtitleDialog = computed(() =>
    transparencyTypesEdited.value ?
      "Rellene los campos correctamente para modificar los datos de la transparencia" :
      "Rellene los campos correctamente para validar los datos de la transparencia"
  );

  const closeDialog = () => {
    dialog.value = false;
    transparencyTypesEdited.value = null;
  }

  // methods
  const getTransparencyTypesList = async () => {
    const { data, status } = await store.dispatch("fetchTransparencyTypes")
    if (status != 200) return;
    transparencyTypes.value = data;
  }

  const createTransparencyTypes = async (transparencyTypes) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createTransparencyTypes", transparencyTypes)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getTransparencyTypesList();
    closeDialog();
  }

  const updateTransparencyTypes = async (transparencyTypes) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateTransparencyTypes", transparencyTypes)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getTransparencyTypesList();
    closeDialog();
  }

  const removeTransparencyTypes = async (transparencyTypes) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeTransparencyTypes", transparencyTypes)
    if (status != 200 && status != 201 && status != 204) return;
    await getTransparencyTypesList();
  }

  getTransparencyTypesList();

  return {
    dialog,
    transparencyTypesEdited,
    transparencyTypes,
    search,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(transparencyTypes) {
      dialog.value = true;
      transparencyTypesEdited.value = { ...transparencyTypes };
    },
    openRemove: removeTransparencyTypes,
    closeDialog,
    createTransparencyTypes,
    updateTransparencyTypes
  };
}

export default useTransparencyTypes;

<template>
  <v-card>
    <TransparencyTypesHeader @openToAdd="openDialog" @search="changeSearch"></TransparencyTypesHeader>
    <DatatableTransparencyTypes
      :transparencyTypes="transparencyTypes"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableTransparencyTypes>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormTransparency
          v-if="dialog"
          :loading="loading"
          :transparencyTypesEdited="transparencyTypesEdited"
          @beforeUpdate="updateTransparencyTypes"
          @beforeCreate="createTransparencyTypes"
        ></FormTransparency>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableTransparencyTypes from './DatatableTransparencyTypes.vue'
import FormTransparency from '@/components/forms/FormTransparency.vue'
import TransparencyTypesHeader from './TransparencyTypesHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useTransparencyTypes from '@/composables/useTransparencyTypes'

export default {
  components: {
    DatatableTransparencyTypes,
    FormTransparency,
    TransparencyTypesHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      transparencyTypesEdited,
      transparencyTypes,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createTransparencyTypes,
      updateTransparencyTypes,
    } = useTransparencyTypes()

    return {
      dialog,
      transparencyTypesEdited,
      transparencyTypes,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createTransparencyTypes,
      updateTransparencyTypes,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
